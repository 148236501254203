// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wanze_text__pbV9l {\n  margin-left: 10px;\n}\n\n.wanze_footer__ZtXXo {\n  border: 1px solid #000000;\n  border-radius: 10px;\n  background-color: #f5f5f5;\n  margin-top: 10px;\n}\n\n.wanze_footer__ZtXXo p {\n  margin-left: 10px;\n}\n\n.wanze_end__uITHY {\n  margin: 8px 0 20px 0;\n}", "",{"version":3,"sources":["webpack://./src/views/wanze.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".text {\n  margin-left: 10px;\n}\n\n.footer {\n  border: 1px solid #000000;\n  border-radius: 10px;\n  background-color: #f5f5f5;\n  margin-top: 10px;\n}\n\n.footer p {\n  margin-left: 10px;\n}\n\n.end {\n  margin: 8px 0 20px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "wanze_text__pbV9l",
	"footer": "wanze_footer__ZtXXo",
	"end": "wanze_end__uITHY"
};
export default ___CSS_LOADER_EXPORT___;
