// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Image_image__y6uZu {\n  border: 1px solid #000000;\n  border-radius: 10px;\n  background-color: #f5f5f5;\n}\n\nimg {\n  width: 100%;\n  border-radius: 9px 9px 0 0;\n}", "",{"version":3,"sources":["webpack://./src/components/image/Image.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".image {\n  border: 1px solid #000000;\n  border-radius: 10px;\n  background-color: #f5f5f5;\n}\n\nimg {\n  width: 100%;\n  border-radius: 9px 9px 0 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "Image_image__y6uZu"
};
export default ___CSS_LOADER_EXPORT___;
