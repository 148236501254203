import React from "react";
import {HashLink as Link} from "react-router-hash-link";

import styles from './separator.module.css';

function Separator(props) {

  return (
    <div className={styles.container} id={props.jumppoint}>
      <h3 className={styles.h3}>{props.header}</h3>
      <Link to={props.link} className="link">{props.linkname}</Link>
      <div className={styles.clear}></div>
    </div>
  )

}

export default Separator;