import React from "react";
import Grid from '@mui/material/Grid';
import {BrowserRouter} from "react-router-dom";
import {HashLink as Link} from "react-router-hash-link";

import Image from "../components/image/Image";
import Separator from "../components/separator/Separator";

import styles from './wanze.module.css';

function Wanze() {

  const header = "Wanze";

  const separators = [
    { id: 0, header: "vor dem Kippen :", linkname: "nach dem Kippen", jumppoint: "full", link: "#empty" },
    { id: 1, header: "nach dem Kippen :", linkname: "vor dem Kippen", jumppoint: "empty", link: "#full" }
  ]

  const contentList = [
    [
      [
        <p>Ankunft Wanze</p>,
        <p>Sprache wählen</p>,
      ],
      [
        <p>Sprache auf Englisch</p>,
      ],
      [
        <p>DPD</p>,
      ],
      [
        <p>Arrival</p>,
      ],
      [
        <p>Bestellnummer vom</p>,
        <p>Lieferschein eintragen</p>,
        <p>beginnt mit 450xxxxxxx</p>
      ],
      [
        <p>Daten mit OK</p>,
        <p>bestätigen !</p>,
        <p>Zettel mit Barcode</p>,
        <p>mitnehmen</p>
      ],
    ],
    [
      [
        <p>nach dem kippen:</p>,
        <p>Sprache wählen</p>,
      ],
      [
        <p>Sprache auf Englisch</p>,
      ],
      [
        <p>DPD</p>,
      ],
      [
        <p>Leaving</p>,
      ],
      [
        <p>Zettel mit Barcode</p>
      ],
      [
        <p>hier scannen</p>
      ],
      [
        <p>LKW-Kennzeichen</p>,
        <p>eintragen</p>
      ],
      [
        <p>Gewicht vom Lieferschein</p>,
        <p>eintragen</p>
      ],
      [
        <p>A-Nummer eintragen</p>
      ],
      [
        <p>alle Daten mit „OK“</p>,
        <p>bestätigen.</p>,
        <p>Wiegeschein wird gedruckt</p>
      ],
      [
        <p style={{ fontWeight: 'bold' }}>Kopie vom Wiegeschein anfertigen !!!</p>,
        /*<p>„Réimprimer le ticket“</p>,*/
        <p>Mit der Maus auf „Réimprimer le ticket“ klicken.</p>,
        <p>Kopie vom Wiegeschein wird erstellt.</p>,
        /*<p>Falls der Knopf nicht funktioniert. Bitte die Maus und den Button auf dem Bildschirm benutzen.</p>*/
      ],
    ]
  ]

  function content(id = 0) {
    return (
      contentList[id].map((text, index) =>
        <Image
          image={header.toLowerCase() + "/" + header.toLowerCase() + "-" + (id + 1) + "-" + (index + 1) + ".jpg"}
          name={header + " " + (id + 1) + "-" + (index + 1)}
          text={
            <div className={styles.text}>
              {text}
            </div>
          }
        />
      )
    )
  }

  const contentLoop = separators.map((separator) =>
    <div>
      <Separator
        header={separator.header}
        linkname={separator.linkname}
        jumppoint={separator.jumppoint}
        link={separator.link}
      />

      <Grid container spacing={1}>
        {content(separator.id)}
      </Grid>
    </div>
  )

  return (
    <BrowserRouter>
      <h2 id="top">{header}</h2>

      {contentLoop}

      <div className={styles.footer}>
        <p>1 Lieferschein,</p>
        <p>1 CMR und</p>
        <p>1 Wiegekarte zusammen in den Briefkasten</p>
        <p>rechts neben dem Automaten !</p>
      </div>

      <Link to="#top" className={`link ${styles.end}`}>Seitenanfang</Link>
    </BrowserRouter>
  )

}

export default Wanze;