import React from "react";
import Grid from "@mui/material/Grid";

import styles from './Image.module.css';

function Image(props) {

  return (
    <Grid item xs={12} sm={6} md={4} xl={3}>
      <div className={styles.image}>
        <img src={`${process.env.PUBLIC_URL}/images/${props.image}`} alt={props.name} />
        {props.text}
      </div>
    </Grid>
  )

}

export default Image;