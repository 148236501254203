// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".separator_container__4JELH {\n  padding: 0;\n  margin-top: 10px;\n  border: 1px solid #000000;\n  border-radius: 10px;\n  background-color: #ffffff;\n}\n\n.separator_clear__-f0sZ {\n  clear: both;\n}\n\n.separator_h3__R-dsI {\n  padding: 0;\n  margin: 4px;\n  margin-left: 8px;\n  float: left;\n}", "",{"version":3,"sources":["webpack://./src/components/separator/separator.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;EACV,WAAW;EACX,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".container {\n  padding: 0;\n  margin-top: 10px;\n  border: 1px solid #000000;\n  border-radius: 10px;\n  background-color: #ffffff;\n}\n\n.clear {\n  clear: both;\n}\n\n.h3 {\n  padding: 0;\n  margin: 4px;\n  margin-left: 8px;\n  float: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "separator_container__4JELH",
	"clear": "separator_clear__-f0sZ",
	"h3": "separator_h3__R-dsI"
};
export default ___CSS_LOADER_EXPORT___;
